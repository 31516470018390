const rentalSpace = {
  state: {
    pageType: 'list',
    beginUpdate: new Date(),
    spaceId: null,
    chkDeviceArray: []
  },
  mutations: {
    set_space_pageType (state, data) {
      state.pageType = data
    },
    set_space_update (state, data) {
      state.beginUpdate = data
    },
    set_space_spaceId (state, data) {
      state.spaceId = data
    },
    set_space_chkDeviceArray (state, data) {
      state.chkDeviceArray = data
    }
  },
  getters: {

  },
  actions: {

  }
}
export default rentalSpace
