<template>
  <container>
    <div slot="bottom_box_content">
        <component :is="curBottomComponent"></component>
    </div>
  </container>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'
import rentalSpaceStoreModule from '@/store/modules/rentalSpace'

export default {
  mixins: [sysMixins],
  components: {
    Container,
    IndexTable: () => import('./components/Bottom')
  },
  created () {
    if (!this.$store.hasModule('rentalSpace')) {
      this.$store.registerModule('rentalSpace', rentalSpaceStoreModule)
    }
  },
  mounted () {
    this.setShowLeftBlock(false)
    this.setShowRightBlock(false)
    this.setShowMap(false)
    this.setBottomHeight('90%')
    this.setLeftComponent('')
    this.setRightComponent('')
    this.setBottomComponent('IndexTable')
  },
  destroyed () {
    this.$store.unregisterModule('rentalSpace')
  }
}
</script>

<style>

</style>
